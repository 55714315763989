



















































































































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MGenerateDiscountCode: () =>
      import("@/components/molecules/m-generate-discount-code.vue"),
  },
  setup(_, { root }) {
    const { goBack, copyAllCodes } = useMisc({ root });
    const model = reactive({
      name: "",
      discountType: "percent",
      value: "",
      startDate: "",
      endDate: "",
      discountCodeType: "multiple use",
      amount: "",
      singleDiscountCode: [] as any,
      multipleDiscountCode: {
        id: "",
        used: false,
        value: "",
      } as any,
      status: false,
      isGlobal: false,
      assignedToModule: null,
    });

    const state = reactive({
      unlimitedAmount: true,
      codeGenerator: false,
      discountCodes: [] as { value: string }[],
      singleCode: "",
      loading: false,
      success: false,
      error: false as boolean | number,
      valid: false,
    });

    const { discountType } = useMisc({ root });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("event.panel.config.discounts.add.error404")}`;
        case 409:
          return `${root.$tc("event.panel.config.discounts.add.error409")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/discount/${root.$route.params.did}`)
        .then(({ data }) => {
          model.name = data.name;
          model.discountType = data.type;
          model.value = data.value;
          model.startDate = data.startDate;
          model.endDate = data.endDate;
          model.discountCodeType = data.codeType;
          model.amount = data.amount;
          model.status = data.status;
          model.singleDiscountCode =
            model.discountCodeType === "single use" ? data.codes : undefined;
          model.multipleDiscountCode =
            model.discountCodeType === "multiple use"
              ? data.codes[0]
              : undefined;
          state.unlimitedAmount = data.amount ? true : false;
          model.assignedToModule = data.assignedToModule || null;
          model.isGlobal = data.isGlobal || false;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const getCodes = (value: string[]) => {
      model.singleDiscountCode = value.map((e) => ({ value: e }));
    };

    const removeCode = (index: number) => {
      model.singleDiscountCode.splice(index, 1);
    };

    const copySingleCode = (index: number) => {
      navigator.clipboard.writeText(model.singleDiscountCode[index].value);
    };

    const addSingleCode = () => {
      model.singleDiscountCode.push({
        value: state.singleCode,
      });
      state.singleCode = "";
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    watch(
      () => model.isGlobal,
      () => {
        if (model.isGlobal === false) {
          model.assignedToModule = null;
        }
      }
    );

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name,
        type: model.discountType,
        value: model.value,
        startDate: model.startDate || undefined,
        endDate: model.endDate || undefined,
        amount:
          model.discountCodeType === "single use"
            ? null
            : state.unlimitedAmount
            ? model.amount
            : null,
        status: model.status,
        codes:
          model.discountCodeType === "multiple use"
            ? [model.multipleDiscountCode].map((el: any) => ({
                id: el.id,
                value: el.value,
                used: el.used,
              }))
            : model.singleDiscountCode.map((el: any) => ({
                id: el.id,
                value: el.value,
                used: el.used,
              })),
        isGlobal: model.isGlobal || false,
        assignedToModule: model.assignedToModule || null,
      };

      state.loading = true;

      axiosInstance
        .put(`discount/${root.$route.params.did}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("event.panel.config.discounts.edit.success"),
          });
          setTimeout(() => {
            goBack("panel.event.view.discount.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return {
      model,
      state,
      getCodes,
      removeCode,
      copySingleCode,
      addSingleCode,
      copyAllCodes,
      rules,
      discountType,
      onSubmit,
    };
  },
});
